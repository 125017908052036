@use '@angular/material' as mat;

// Theme Color Variables
$theme-primary: mat.define-palette(mat.$indigo-palette);
$theme-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$theme-warn: mat.define-palette(mat.$red-palette);

// Create CSS Custom Properties
:root {
  // Primary Colors
  --primary-color: #{mat.get-color-from-palette($theme-primary)};
  --primary-lighter: #{mat.get-color-from-palette($theme-primary, lighter)};
  --primary-darker: #{mat.get-color-from-palette($theme-primary, darker)};

  // Accent Colors
  --accent-color: #{mat.get-color-from-palette($theme-accent)};
  --accent-lighter: #{mat.get-color-from-palette($theme-accent, lighter)};
  --accent-darker: #{mat.get-color-from-palette($theme-accent, darker)};

  // Warning Colors
  --warn-color: #{mat.get-color-from-palette($theme-warn)};
}

// Utility Classes
.bg-primary {
  background-color: var(--primary-color);
}
.bg-primary-lighter {
  background-color: var(--primary-lighter);
}
.bg-primary-darker {
  background-color: var(--primary-darker);
}

.bg-accent {
  background-color: var(--accent-color);
}
.bg-accent-lighter {
  background-color: var(--accent-lighter);
}
.bg-accent-darker {
  background-color: var(--accent-darker);
}

.bg-warn {
  background-color: var(--warn-color);
}

.text-primary {
  color: var(--primary-color);
}
.text-accent {
  color: var(--accent-color);
}
.text-warn {
  color: var(--warn-color);
}
