/* You can add global styles to this file, and also import other style files */
@import '../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css';
@import '../../node_modules/@ng-select/ng-select/themes/default.theme.css';

html {
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  // Diese Styles verursachen Probleme, deshalb habe ich diese auskommentiert - MO
  //background-color: #f5f5f5 !important;
  //height: 100%;
  //overflow-x: hidden;
  //visibility: visible;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.87);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 14px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.mat-drawer-container {
  background-color: unset !important;
}

.form-sticky {
  position: fixed;
  top: 50px !important;
  right: 0px;
  left: 78px !important;
  transition: top 0.3s ease-in-out;
  z-index: 998;
  padding: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.form-sticky .breadcrumb {
  background: white;
}

.form-content {
  margin-top: 59px;
}

.mat-drawer {
  position: fixed !important;
  padding-top: 60px;

  .mat-drawer-content {
    overflow-x: hidden !important;
  }
}

.primary-button,
.primary-bg {
  background: #00838f;
  border-color: #00838f;
  color: #ffffff;
}

.primary-button:hover {
  background: #00838f;
  border-color: #00838f;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
}

.danger-button {
  background-color: #e53935;
  border-color: #e53935;
  color: #ffffff;
}

.danger-button:hover {
  background: #e53935;
  border-color: #e53935;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
}

.mat-drawer.mat-drawer-side.mat-sidenav {
  z-index: 1;
}

.success-background {
  background-color: #43a047 !important;
}

.danger-background {
  background-color: #e53935 !important;
}

.md-bg-blue-600 {
  background-color: #1e88e5 !important;
}

.md-bg-green-600 {
  background-color: #43a047 !important;
}

.md-bg-light-green-600 {
  background-color: #7cb342 !important;
}

.primary-background {
  background-color: #2196f3 !important;
}

.transparent-background {
  background-color: #ffffff !important;
}

.success-badge {
  background-color: #43a047 !important;
}

.success-button {
  background: #7cb342;
  border-color: #7cb342;
  color: #ffffff;
}

.success-button:hover {
  background: #7cb342;
  border-color: #7cb342;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
}

.success-button:active {
  background: #7cb342;
  border-color: #7cb342;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
}

.warning-button {
  background-color: #ffca2c;
  border-color: #ffc720;
  color: #ffffff;
}

.warning-button:hover {
  background-color: #ffca2c;
  border-color: #ffc720;
  color: #ffffff;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
}

.warning-button:active {
  background-color: #ffca2c;
  border-color: #ffc720;
  -webkit-box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2),
    0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12);
  text-decoration: none;
}

.bold {
  font-weight: bold;
}

.fs-7 {
  font-size: 0.76rem !important;
}

.mat-mdc-dialog-surface {
  overflow: hidden;
}

.mat-mdc-tab-body-content {
  overflow: hidden !important;
}

.default-color {
  background-color: #2196f3; /* Blue */
}

@mixin margin-snackbar {
  margin-top: 49px;
  margin-right: 15px;
}

.mat-mdc-snack-bar-container {
  &.snackbar-success {
    --mdc-snackbar-container-color: #7cb342;
    --mat-mdc-snack-bar-button-color: #fff !important;
    --mdc-snackbar-supporting-text-color: #fff;
    @include margin-snackbar;

    .mdc-button__label {
      color: #ffffff;
    }
  }

  &.snackbar-error {
    --mdc-snackbar-container-color: #f44336;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    @include margin-snackbar;

    .mdc-button__label {
      color: #ffffff;
    }
  }

  &.snackbar-warning {
    --mdc-snackbar-container-color: #ff9800;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    @include margin-snackbar;

    .mdc-button__label {
      color: #ffffff;
    }
  }

  &.snackbar-info {
    --mdc-snackbar-container-color: #00bcd4;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    @include margin-snackbar;
  }
}

// comment  this code add loading to div "add class card-overload"

.card-overload {
  position: relative;
}

/* Absolute Center Spinner */
.loading {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-color: rgba(255, 255, 255, 0.8);
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.red-bg {
  background-color: lightpink !important;
}

.dataTable {
  padding-top: 16px;
}

.icon-button {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.54);
}

.icon-button:hover {
  color: rgba(0, 0, 0, 0.8);
  transform: scale(1.1);
}

.btn-full-width {
  width: 100%;
}

.shadow-10 {
  box-shadow: 0px 0px 10px #ccc;
}

.example-form {
  background-color: #fff;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blue-background .ng-select-container {
  background-color: #2196f3 !important;
  color: #ffffff;
  animation: blinker 1s linear infinite;
}

.purple-background .ng-select-container {
  background-color: #e80af0 !important;
  color: #ffffff;
}

.orange-background .ng-select-container {
  background-color: #ff9752 !important;
  color: #ffffff;
}

.yellow-background .ng-select-container {
  background-color: #c0ca33 !important;
  color: #ffffff;
}

.red-background .ng-select-container {
  background-color: #e53935 !important;
  color: #ffffff;
}

.light-red-background .ng-select-container {
  background-color: #ff7074 !important;
  color: #000000;
}

.light-green-background .ng-select-container {
  background-color: #90ee90 !important;
  color: #000000;
}

.green-background .ng-select-container {
  background-color: #43a047 !important;
  color: #ffffff;
}

.green-background .ng-arrow,
.red-background .ng-arrow,
.blue-background .ng-arrow {
  border-color: #fff transparent transparent !important;
}

app-root {
  flex: 1;
}

mat-sidenav-container {
  height: 100%;
}

.card-head-red-bg .card-header {
  background-color: rgb(245, 183, 177);
  color: #ffffff;
}

.mdc-evolution-chip__action--presentational {
  cursor: pointer !important;
}

.red-bold-text {
  color: red;
  font-weight: bold;
}

.custom-badge {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  gap: 6px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;

  mat-icon {
    font-size: 14px;
    width: 14px;
    height: 14px;
  }

  &.badge-blue {
    background: #deebff;
    color: #0747a6;
    border: 1px solid #0747a6;
  }

  &.badge-purple {
    background: #eae6ff;
    color: #403294;
    border: 1px solid #403294;
  }

  &.badge-yellow {
    background: #fff0b3;
    color: #172b4d;
    border: 1px solid #ff991f;
  }

  &.badge-red {
    background: #ffebe6;
    color: #bf2600;
    border: 1px solid #bf2600;
  }

  &.badge-green {
    background: #e3fcef;
    color: #006644;
    border: 1px solid #006644;
  }

  &.badge-gray {
    background: #f4f5f7;
    color: #42526e;
    border: 1px solid #42526e;
  }
}
